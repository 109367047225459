@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,600;0,700;0,800;0,900;1,400&display=swap');

:root {
    --lightgreen: #9FC700;
    --black: #232323;
    --headline-black:#232323;
    --main-background: #fff;
    --white: #fff;
    --lightgrey:#717171;
    --background-color: #fafafa;
    --border-color:#d2d2d2;
    --forest-dark:#042F38;

    --fontfamily: 'Poppins', sans-serif;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    background: var(--main-background);
    color: var(--black);
    font-family: var(--fontfamily);
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding:0px;
    margin:0 auto;
    height: 100%;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased !important;
	text-rendering: optimizeLegibility;
	font-feature-settings: "kern";
	-webkit-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern=1";
}

a {
  text-decoration: none;
}

a, body {
  color: var(--black);
}

figure {
  background-color: #f7f7f7;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  z-index: 1;
  margin: 0;
  padding: 0;
  border-radius: 12px;
}

figure a {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top:0;
  left:0;
  opacity: 0;
}

div.content {
  padding: 0;
  max-width: 1400px;
  margin: 0 auto;
}

.projects_items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}

.wrapper {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.projects_items .item {
  display: inline-block;
  width: 25%;
  flex: 25% 1;
  max-width: 25%;
  padding-right: 20px;
  margin: 20px 0;
}

.projects_items .item figure {
  height: 225px;
  border-radius: 12px;
  width: 100%;
  clear: both;
  float: none;
}

.tripContainer {
  width: 100%;
  max-width: 1400px;
  margin: 40px auto;
  text-align:left;
}

.ferdin {
  width: calc(100% - 0px);
  margin-top: 30px;
}

.ferdin .trip_extra {
  width: 100%;
}

h1.tripname {
  margin: 10px 0 !important;
  font-weight: 600;
  font-size: 34px;
  line-height: 1;
}

.myndasafn {
  width:100%;
  height:400px;
  margin-bottom:20px
 }
 .myndasafn .alpha {
  width:30%;
  display:inline-block;
  padding-right:5px;
  vertical-align:top
 }
 .myndasafn .beta {
  width:21%
 }
 .myndasafn .beta,
 .myndasafn .gamma {
  display:inline-block;
  padding:0 5px;
  vertical-align:top
 }
 .myndasafn .gamma {
  width:18%
 }
 .myndasafn .delta {
  width:31%;
  display:inline-block;
  padding-left:5px;
  vertical-align:top;
  position:relative
 }
 .myndasafn div figure {
  width:100%;
  height:400px;
  vertical-align:top;
  border-radius:12px
}

.myndasafn .gamma figure {
  width:100%;
  height:195px;
  margin-top:11px
}

.myndasafn .delta figure {
  height:400px
}

.myndasafn .delta a {
  display:inline-block;
  width:100%;
  text-align:center;
  border:1px solid #ccc;
  font-weight:400;
  font-size:15px;
  padding:10px;
  margin-top:11px;
  border-radius:12px
}

.myndasafn .gamma figure:first-child {
  margin-bottom:5px;
  margin-top:0
 }
 h2.tripinfo {
  margin:0 0 30px;
  font-weight:400
 }
 .tripinfo h2 {
  font-weight:700;
  margin:50px 0 20px
 }
 .tripinfo h2,
 .tripinfo h3 {
  font-size:24px;
  color:var(--headline-black)
 }
 .tripinfo h3 {
  font-weight:600
 }
 .ferdin {
  width:calc(100% - 0px);
  margin-top:30px
 }
 .ferdin.w {
  width:calc(100% - 400px);
  margin-top:30px;
  display:inline-block;
  vertical-align:top;
  padding-right:50px;
  text-align:left;
 }
 h1.tripname {
  margin:10px 0!important;
  font-weight:600;
  font-size:34px;
  line-height:1
 }
 h3.tripname {
  margin:0 0 30px!important;
  font-weight:200;
  font-size:24px;
  line-height:1
 }
 h3.tripname p {
  margin:0!important
 }
 .traveler {
  width:100%;
  margin-bottom:20px;
  font-size:24px;
  font-family:var(--fontfamily);
  font-weight:300
 }
 .traveler .by a {
  font-weight:900
 }
 .ferdin .trip_extra {
  width:100%
 }
 .ferdin .trip_extra .trip_info {
  display:inline-block;
  width:50%;
  vertical-align:top
 }
 .ferdin .trip_extra .trip_info .trip_days {
  display:inline-block;
  margin:5px 10px 0 0;
  background:var(--lightgreen);
  color:#fff;
  padding:4px 8px;
  border-radius:12px;
  font-size:14px
 }
 .ferdin .efnid {
  width:calc(100% - 0px);
  margin-right:0
 }
 .ferdin .bokun,
 .ferdin .efnid {
  display:inline-block;
  vertical-align:top
 }
 .ferdin .bokun {
  width:30%;
  padding:20px;
  border:1px solid #f8f8f8;
  border-radius:12px;
  background-color:#f8f8f8
 }
 .bokun h3 {
  font-weight:700;
  color:var(--black);
  font-size:26px
 }
 ul.tags {
  list-style-type:none;
  padding:0;
  margin:20px 0;
  display:flex;
  flex-direction:row;
  justify-content:left;
  align-items:left;
  gap:10px;
  display:none
 }
 ul.tags li button {
  display:block;
  padding:9px 16px;
  border:1px solid var(--border-color);
  color:var(--black);
  background:#fff;
  cursor:pointer;
  font-weight:400;
  font-size:14px;
  border-radius:12px
 }
 ul.tags li.selected button,
 ul.tags li:hover button {
  border:1px solid var(--lightgreen)
 }
 ul.tags li.selected button {
  color:var(--white);
  background:var(--lightgreen)
 }
 .travelarchitects {
  width:100%;
  margin:40px 0
 }
 .travelarchitects .travelarchitect {
  width:25%;
  margin:0;
  display:inline-block;
  padding-right:10px
 }
 .travelarchitects .travelarchitect figure {
  height:385px;
  width:100%;
  border-radius:12px;
  margin:0 auto;
  display:inline-block;
  float:none
 }
 .travelarchitects .travelarchitect h3 {
  width:cacl(100% - 10px);
  font-weight:600;
  font-size:20px;
  clear:both;
  margin:10px 5px 5px
 }
 .travelarchitects .travelarchitect h4 {
  width:cacl(100% - 10px);
  font-weight:300;
  clear:both;
  margin:0 5px 10px;
  color:var(--lightgrey)
 }


 .ferdin .bokun, .ferdin .efnid {
  display: inline-block;
  vertical-align: top;
}
.ferdin .efnid {
  width: calc(100% - 0px);
  margin-right: 0;
}

.tripinfo {
  font-size: 18px;
  line-height: 1.5;
}

.inc-pack {
  width: calc(33.333334% - 8px);
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 12px;
  min-height: 160px;
}

.inc-pack:last-child {
  margin-right: 0;
}

.air_icon {
  display:block;
  height:40px;
  width:40px;
  clear:both;
  margin:0
}

.air_icon.regular {
  height:50px;
  width:50px;
  background-size:cover
}

.air_icon.icon_accommodation {
  background-image:url(../public/images/icon/icon_accommodation.svg)
}

.air_icon.icon_transportation {
  background-image:url(../public/images/icon/icon_transportation.svg)
}

.air_icon.icon_charging {
  background-image:url(../public/images/icon/icon_charging.svg)
}

.airmango-tour {
  width:100%;
  margin:0
 }
 .moredates {
  display:inline-block;
  padding:18px 32px;
  border:1px solid #d2d2d2;
  border-radius:40px;
  background-color:transparent;
  font-size:16px
 }
 .tripinfo {
  font-size:18px;
  line-height:1.5
 }
 p.tripinfo {
  margin:-20px 0 30px;
  font-size:20px;
  color:var(--lightgrey)
 }
 .behind_blog a {
  color:#9fc700;
  font-size:18px;
  font-weight:400;
  -webkit-text-decoration:underline 1px #444;
  text-decoration:underline 1px #444
 }
 .thinkstoknow>* {
  flex:1 1
 }
 .availabledates {
  width:100%
 }
 .availabledates .availabledate {
  width:16.666666666%;
  display:inline-block;
  padding:10px
 }
 .availabledates .availabledate .cost,
 .availabledates .availabledate .when {
  margin:0 0 10px;
  padding:0 10px
 }
 .availabledates button {
  width:100%;
  display:inline-block;
  background-color:var(--lightgreen);
  border-radius:12px;
  border:none;
  padding:0 10px;
  text-align:center;
  color:#fff;
  height:40px;
  line-height:40px
 }
 .tour_dates {
  width:100%;
  margin-top:20px
 }
 .joinusscreen {
  background-color:var(--black);
  width:33%;
  border-radius:12px;
  display:inline-block;
  vertical-align:top;
  padding:20px;
  color:#fff;
  margin-top:80px
 }

 .itineraryitem {
  width:calc(100% - 10px);
  padding-right:0;
  display:inline-block;
  vertical-align:top;
  padding-bottom:40px;
  margin-right:10px
 }
 .itineraryitem .itinerary {
  position:relative
 }
 .itineraryitem .gallery {
  width:50%;
  display:inline-block;
  vertical-align:top
 }
 .itineraryitem .mainimg {
  width:60%;
  height:230px;
  display:inline-block;
  vertical-align:top;
  position:relative
 }
 .itineraryitem .xtraimg {
  width:40%;
  height:220px;
  padding-left:5px;
  display:inline-block;
  vertical-align:top
 }
 .itineraryitem .mainimg figure {
  height:100%;
  margin:0 0 10px;
  padding-right:5px
}

.itineraryitem .mainimg figure, .itineraryitem .xtraimg figure {
  width:100%;
  display:inline-block;
  vertical-align:top;
  border-radius:12px;
  background-size:cover
}

.itineraryitem .xtraimg figure {
  height:113px;
  margin:0 0 5px;
  padding-right:10px
}

.itineraryitem .itinerary_info {
  width:50%;
  display:inline-block;
  vertical-align:top;
  padding-left:20px;
  overflow:hidden;
  min-height:231px;
  position:relative
}

.itineraryitem .itinerary_info button {
  display:inline-block;
  width:calc(100% - 20px);
  text-align:center;
  border:1px solid #ccc;
  font-weight:400;
  font-size:15px;
  padding:10px;
  margin-top:11px;
  border-radius:12px;
  cursor:pointer;
  position:absolute;
  bottom:0;
  background: #fff;
}

.itineraryitem .itinerary_info button:hover {
  border:1px solid var(--lightgreen)
}

.moreinfo-text {
  height:260px;
  overflow:hidden;
  width:100%
}

.moreinfo-text .itinerary-modal-desc p:first-child {
  margin-top:0
 }
 .itineraryitem .itinerary_info .morinfo-btn {
  background-color:transparent;
  color:var(--black)!important;
  border:1px solid #ccc;
  display:inline-block;
  width:100%;
  text-align:center;
  font-weight:400;
  font-size:16px;
  padding:10px;
  margin-top:11px;
  border-radius:12px;
  transition:.4s ease-in-out;
  cursor:pointer
 }
 .itineraryitem .itinerary_info .morinfo-btn:hover {
  background-color:#f3f9fc
 }
 .itineraryitem .itinerary_info h2 {
  margin:0;
  font-size:16px;
  font-weight:500
 }
 .itineraryitem h3 {
  margin:0;
  padding:0;
  font-size:20px;
  font-weight:700
 }
 .itineraryitem h3.gamla {
  font-weight:400;
  font-size:14px;
  color:var(--black);
  position:absolute;
  top:10px;
  left:10px;
  z-index:1;
  background:#fff;
  padding:5px 10px;
  border-radius:12px
 }
 .itineraryitem h3 span {
  color:var(--lightgreen)
 }
 .itineraryitem h4 {
  font-weight:300;
  font-size:18px;
  margin:0;
  color:var(--lightgrey)
}

.itineraryitem p {
  margin:10px 0;
  font-size:16px
}

.bokunarvel, .requestbooking, .sidebar {
  padding:10px;
  max-width:400px;
  width:100%;
  margin:0 auto;
  position:relative;
  display:inline-block;
  font-size:16px;
  text-align:left;
}
.sidebar {
  padding:20px 0;
  font-size:20px
}

.inc-pack h3 {
  margin: 10px 0 5px;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
}

.inc-pack p {
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 1.1 !important;
}

.more_info_day {
  position:fixed;
  background-color:#fff;
  width:calc(100% - 0px);
  height:calc(100% - 0px);
  margin:0px;
  top:0;
  left:0;
  z-index:-1;
  display:none
}

.more_info_day.open {
  display:block;
  background-color:hsla(0,0%,100%,1);
  z-index:21
}

.close_me {
  display:inline-block;
  border:1px solid var(--border-color);
  color:#232323;
  padding:5px 18px;
  border-radius:50px;
  text-align:center;
  font-weight:400;
  font-size:26px;
  cursor:pointer;
  margin:15px 0;
  position:absolute;
  left:20px;
  top:0;
  background-color: #fff;
}

.close_me:hover {
  border:1px solid var(--lightgreen);
  color:var(--lightgreen)
}

.todayisgoingtobe {
  display:inline-block;
  border:1px solid var(--border-color);
  color:#232323;
  padding:5px 20px;
  border-radius:12px;
  text-align:center;
  font-weight:400;
  font-size:16px;
  cursor:text;
  margin:15px 0
}

.more_info_day h3.day {
  margin:40px 0 10px;
  font-size:22px
}

.more_info_day h2 {
  margin:0;
  font-size:32px
}

.more_info_day h3.subject {
  margin:0;
  font-size:22px;
  font-weight:300
}

.scrollingmydayaway {
  width: 100%;
  height: calc(100vh - 20px);
  overflow: auto;
  padding-top: 60px;
}

.howtogetthere iframe {
  width: 100%;
}

.tripinfo figure {
  max-width: 100%;
  background-color: transparent;
}

.tripinfo figcaption {
  font-size: 14px;
  color:#888;
}

header {
  width:100%;
  padding: 0;
  max-width: 1400px;
  margin: 10px auto;
  height: 90px;
}

.merki {
  display: inline-block;
  background-image: url(/public/images/Northern-lights-iceland-0x105.png);
  float: left;
  height: 90px;
  width: 110px;
  background-size: cover;
  font-size: 0;
}

.bokunarvel_h1 {
  font-size: 26px;
  margin: 0 0 20px;
}

.giftcard {
  width:100%
}
.giftcard input[type=text] {
  width:76%
}
.giftcard button,
.giftcard input[type=text] {
  padding:10px;
  font-family:Work Sans,sans-serif;
  font-weight:400;
  border-radius:6px;
  border:1px solid #ccc
}
.giftcard button {
  width:20%;
  margin:0 0 0 4%;
  font-size:15px;
  font-weight:500
}

footer {
  width:100%;
  text-align: left;
  background-color: #fafafa;
  padding: 30px 0;
  font-size: 15px;
}

header ul {
  display: inline-block;
  float: right;
  margin-right: 20px;
  font-size: 16px;
}


header ul li {
  display: inline-block;
  margin: 20px 5px;
}

header ul li i {
  font-size: 20px;
}

.submenu {
  padding: 10px 0;
  width: 100%;
  max-width: 1400px;
  margin: 20px auto;
}

.submenu ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: left;
}
.submenu ul li {
  display: inline-block;
}

.submenu ul li a {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
}

.submenu ul li span {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
  border: 1px solid #232323;
  background: #232323;
  color:#fff;
}

footer ul {
  display: inline-block;
  float: right;
  margin: 0;
  padding: 0;
}

footer ul li {
  display: inline-block;
  margin: 0 10px;
}

.footer ul li a {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
}

.info {
  display: inline-block;
  float: left;
}

.hidethis {
  display: none !important;
}

.thankyou h2 {
  text-align: left;
}

.thankyou {
  text-align: left;
}