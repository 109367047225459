.sidebar, .bokunarvel, .requestbooking {
    padding: 10px;
    max-width: 400px;
    width: 100%;
    margin:0 auto;
    position: relative;
    display: inline-block;
    font-size: 16px;
}

.sidebar {
    padding: 20px 0;
    font-size: 20px;
}

.requestbooking {
    border-radius: 8px;
    border: 1px solid var(--border-color);
}

.requestbooking h3 {
    margin:10px 0 20px;
    font-weight: 300;
    font-size: 20px;
}

.requestbooking h3 strong {
    font-weight: 700;
}

.sidebar.is-sticky {
    position: fixed;
    top: 98px;
}

.requestbooking button.requestnow {
    margin: 10px 0;
    border: 1px solid #9fc700;
    background-color: #9fc700;
    color: #fff;
    line-height: 34px!important;
    padding: 5px 15px!important;
    border-radius: 6px;
    font-weight: 700!important;
    transition: .4s ease-in-out;
    cursor: pointer;
    width: 100%;
    font-size: 18px!important;
    margin-top: 40px;
    height: auto!important;
}
.requestbooking button.requestnow:hover {
    background-color: #7aa81e;
}


.requestbooking .requestinfo {
    margin: 10px 10px;
    width:calc(100% - 20px);
    border-top: 1px solid var(--border-color);
    font-size: 14px;
    padding: 10px 0 0 60px;
    background-image: url(../../public/images/calendar-circle-exclamation-light.svg);
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 10px;
}

.bokunarvel h2 {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
}

.bokunarvel h2.booknowh2 {
    font-weight: 900;
    font-size: 34px;
    margin: 74px 0 20px;
    color: var(--forest-dark);
}

.bokunarvel h3 {
    font-size: 18px;
    margin: 0 0 5px 0;
}

.bokunarvel h3.giftcardh2 {
    margin-bottom: 4px;
    margin-top: 10px;
}

.bokunarvel h3 span {
    display: inline-block;
    float:right;
    background-color: #daeebe;
    color:#271f1e;
    padding: 2px 8px;
    font-weight: 400;
    border-radius: 6px;
    font-size: 14px;
    cursor: auto;
}

.bokunarvel select {
    width: 100%;
    background:#fff;
    padding: 10px;
    border:1px solid #ccc;
    font-family: "Work Sans",sans-serif;
    border-radius: 6px;
    margin-top: 5px;
    font-size: 16px;
}

.ekkertframbod {
    background-color: rgba(252, 238, 241,0.7);
    padding: 10px;
    text-align: center;
    border: 1px solid #eea9b5;
    border-radius: 12px;
    margin: 0 0 10px;
    opacity: 0.8;
}

.ekkertframbod.no { display: none;}

.frambod {
    background: #fff;
    padding: 10px;
    text-align: center;
    border: 1px solid #D2D2D2;
    border-radius: 12px;
    margin: 0 0 10px;
    position: relative;
    transition: 0.3s ease-in-out;
}

.frambod:hover {
    background: #f3f6e8;
    border: 1px solid #9FC700;
}

table tbody tr th {
    color: rgb(97, 118, 136);
    background: rgb(249, 249, 249);
    border-bottom: 1px solid rgb(221, 221, 221);
}

table tbody tr th p {
    margin: 0 !important;
    line-height: 30px;
    font-weight: 400;
    font-size: 14px;
}

.prev_btn, .next_btn {
    font-size: 20px;
    padding: 0;
    width:30px;
    height: 30px;
    border-radius: 15px;
    line-height: 26px;
    border:1px solid #ddd;
    background-color: #fff;
}


.participants {
    display: block;
    position: relative;
    top: 0px;
    left: 0;
    border: 1px solid #D2D2D2;
    padding: 20px 10px 40px;
    z-index: 2;
    border-radius: 8px;
    background-color: #fff;
    width: calc(100% - 0px);
}

.participants.h {
    display: none;
}

.participants_line {
    width: 100%;
    height:40px;
    line-height: 20px;
    margin-bottom: 5px;
}

.participants .change_dates_btn {
    left: auto;
    right:10px;
}


span {cursor:pointer; }

.participants_line .name {
    display: inline-block;
    width: 70%;
    vertical-align: top;
    font-weight: 500;
}

.participants_line .name small {
    font-size: 14px;
    color:rgba(0,0,0,0.7);
    line-height: 14px;
    font-weight: 300;
}

.number {
    display: inline-block;
    text-align: right;
    width: 30%;
    vertical-align: top;
}

.minus, .plus {
    width:30px;
    height:30px;
    background:#fff;
    border-radius:15px;
    line-height: 26px;
    border:1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 16px;
}

.minus.disabled {
    opacity: 0.5;
}

.minus:hover, .plus:hover { 
    border:1px solid #000;
}

.minus.disabled:hover {
    border:1px solid #ddd;
    cursor: not-allowed;
}

.number input {
    height:30px;
    width: 30px;
    text-align: center;
    font-size: 16px;
    border:0px solid #ddd;
    border-radius:4px;
    display: inline-block;
    vertical-align: middle;
    margin:0 3px;
}

.btn_checkout {
    background-color: #9FC700;
    border: 1px solid #9FC700;
    color:#fff;
}

.hvenaer {
    display: inline-block;
    width:35%;
    text-align: left;
    vertical-align: top;
    font-size: 15px;
}

.verdid {
    display: inline-block;
    width:40%;
    vertical-align: top;
    text-align: left;
    font-size: 15px;
}

.velja {
    display: inline-block;
    width:25%;
    text-align: right;
    vertical-align: top;
  }
  
  .velja button {
    border: 1px solid #9FC700;
    background-color: #fff;
    color:#222222;
    line-height: 34px;
    padding: 0 15px;
    border-radius: 6px;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    font-size: 14px;
    position: relative;
  }
  
  .velja button::after {
    content: " Nice ";
    position: absolute;
    height:100%;
    width:100%;
    background-color:#9FC700;
    top: 0;
    left:0;
    color:#fff;
    display: none;
    transition: 0.4s ease-in-out;
    opacity: 0;
  }
  
  .velja button.btn_disabled {
    cursor: not-allowed;
    border: 1px solid #D2D2D2;
    background-color: #F8F8F8;
    color:#222222;
    opacity: 0.6;
  }
  
  .velja button:hover {
    border: 1px solid #9FC700;
    background-color: #9FC700;
    color:#fff;
  }
  
  .velja button:hover:after {
    display: block;
    opacity: 1;
  }
  
  .velja button.btn_disabled:hover {
    border: 1px solid #D2D2D2;
    background-color: #F8F8F8;
  }
  
  .velja button.btn_disabled:hover:after {
    display: none;
  }
  
  .adal_valmoguleikar {
      display: block;
      width:100%;
      border: 1px solid #222222;
      border-radius: 8px;
      position: relative;
      z-index: 1;
  }
  
  .adal_valmoguleikar .dates_val {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    border-right: 1px solid #222222;
    font-size: 15px;
    position: relative;
  }
  
  .adal_valmoguleikar .dates_val strong, .adal_valmoguleikar .participants_val strong {
    display: block;
    font-size: 16px;
  }
  
  .hidethis { display: none; }
  
  .rdrDayNumber {
    font-size: 16px;
  }
  
  .adal_valmoguleikar .participants_val {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    font-size: 15px;
    position: relative;
}

.adal_valmoguleikar .participants_val .arrow, .adal_valmoguleikar .dates_val .arrow {
    position: absolute;
    right:10px;
    top:15px;
    font-weight: 400;
    background-image: url(../../public/images/arrow-down-to-line-solid.svg);
    width: 28px;
    height: 28px;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #9FC700;
    border-radius: 6px;
    cursor: pointer;
}

.adal_valmoguleikar .participants_val .arrow:hover, .adal_valmoguleikar .dates_val .arrow:hover {
    background-color: #f1f6dc;
}


.rdrDefinedRangesWrapper, .rdrDateDisplayWrapper {
    display: none;
}

.rdrDateRangePickerWrapper {
    width: 100%;
}

.rdrCalendarWrapper {
    width: 100%;
}

.rdrMonth {
    width: 100% !important;
}

.velja_dags {
    display: block;
    position: relative;
    top:0px;
    left:0;
    border: 1px solid #D2D2D2;
    padding: 10px;
    z-index: 2;
    border-radius: 8px;
    background-color: #fff;
    width:calc(100% - 0px);
}

.velja_dags.h {
    display: none;
}
.change_dates_btn {
    border: 1px solid #FF385C;
    background-color: #FF385C;
    border-radius: 6px;
    padding: 4px 8px;
    float: right;
    color:#fff;
    font-weight: 600;
    position: absolute;
    left: calc(100% - 38px);
    top: -44px;
    z-index: 10;
    width:28px;
    height:28px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.change_dates_btn:hover {
    border: 1px solid #000;
    background-color: #000;
}

.btn_continue {
    border: 1px solid #9FC700;
    background-color: #9FC700;
    color:#fff;
    line-height: 34px !important;
    padding: 5px 15px !important;
    border-radius: 6px;
    font-weight: 700 !important;
    transition: 0.4s ease-in-out;
    cursor: pointer;
    width: 100%;
    font-size: 18px !important;
    margin-top: 40px;
    height: auto !important;
}

.btn_continue.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.btn_cancel {
    border: 1px solid #fff;
    background-color: #fff;
    color:#FF385C;
    line-height: 34px;
    padding: 0 15px;
    border-radius: 6px;
    font-weight: 600;
    transition: 0.4s ease-in-out;
    cursor: pointer;
    margin:10px 0;
    width: 100%;
}

.basket {
    padding: 10px 0;
    border-radius: 6px;
    font-size: 16px;
}

.basket .alpha {
    display: inline-block;
    width: 70%;
    vertical-align: top;
    margin-bottom: 5px;
}

.basket .gamma {
    display: inline-block;
    width: 30%;
    vertical-align: top;
    margin-bottom: 5px;
    text-align: right;
}

.giftcard {
    width: 100%;
}

.giftcard input[type="text"] {
    width: 76%;
    padding: 10px;
    font-family: "Work Sans",sans-serif;
    font-weight: 400;
    border-radius: 6px;
    border: 1px solid #ccc;
}

.giftcard button {
    width: 20%;
    margin: 0 0 0 4%;
    padding: 10px;
    font-family: "Work Sans",sans-serif;
    font-weight: 400;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 15px;
    font-weight: 500;
}

.xtrax .extra {
    padding: 8px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.xtrax .extra input {
    width:20px;
    display: inline-block;
}

.xtrax .extra h3 {
    display: inline-block;
    width:calc(100% - 30px);
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.xtrax .extra h3 button {
    display: inline-block;
    background-color: #271f1e;
    color:#fff;
    border-radius: 8px;
    border: none;
    width:18px;
    height: 18px;
    line-height: 16px;
    text-align: center;
    font-weight: 700;
    font-size: 13px;
}

.xtrax .extra .moreinfo_xtra {
    opacity: 1;
    height:auto;
    font-size: 15px;
    width:100%;
    transition: 0.3s ease-in-out;
    display: block;
}

.xtrax .extra .moreinfo_xtra.show {
    opacity: 1;
    height:auto;
    width:100%;
}

.efnid {
    display: none;
}

.alpha.hide0, .gamma.hide0 {
    display: none !important;
}

.bokunarvel.paymentform label {
    width:100%;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
    margin-top: 10px;
}

.bokunarvel.paymentform input, .bokunarvel.paymentform textarea {
    width:100%;
    font-size: 16px;
    font-family: "Work Sans",sans-serif;
    font-weight: 400;
    padding: 8px;
    border: 1px solid #b6b6b6;
    border-radius: 4px;
    height:38px;
    margin-top: 5px;
  }
  
  .bokunarvel.paymentform textarea {
    resize: none;
    height: 60px;
  }

.bokunarvel.paymentform input.error {
    border: 1px solid #d51034;
}

.errorMessage {
    margin-top: 2px;
    color:#d51034;
    font-size: 14px;
}

.f_expiry {
    display: inline-block;
    width: 70%;
    vertical-align: top;
}

.bokunarvel.paymentform .f_expiry select {
    width:70px;
    display: inline-block;
    margin-right: 5px;
}

.f_expiry input:last-child {
    margin-left: 10px;
}

.f_cvc {
    display: inline-block;
    width: 30%;
    vertical-align: top;
}

#cvv {margin-top:5px; }

.rdrDayToday .rdrDayNumber span::after {
    background: rgb(159, 199, 0) !important;
}

.hidesolo {
    display: none !important;
}

.hidesolo.hidesolo_1 {
    display: inline-block !important;
}

.basket .ischild_0 {
    display: none !important;
}

.basket .alpha.total, .basket .gamma.total  {
    font-weight: 600;
    padding-top: 5px;
    margin-top: 5px;
    border-top: 1px solid #efefef;
}

.paidinfull {background-color: #8fb302;display: inline-block;border-radius: 8px;padding: 4px 8px;color:#fff;margin-bottom: 10px;}
h3.ticket {
    margin: 0 0 5px;
    font-weight: 500;
}

.hidethis {
    display: none;
}

.giftcard {
    width: 100%;
  }
  
  .giftcard input[type="text"] {
    width: 76% !important;
    padding: 10px !important;
    font-family: "Work Sans",sans-serif;
    font-weight: 400;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  .giftcard button {
    width: 20%;
    margin: 0 0 0 4%;
    padding: 10px;
    font-family: "Work Sans",sans-serif;
    font-weight: 400;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 15px;
    font-weight: 500;
    background-color: #9FC700;
    color:#fff;
  }

  
.basket .alpha.total, .basket .gamma.total  {
    font-weight: 600;
    padding-top: 5px;
    margin-top: 5px;
    border-top: 1px solid #efefef;
  }
  
  .paidinfull {background-color: #8fb302;display: inline-block;border-radius: 8px;padding: 4px 8px;color:#fff;margin-bottom: 10px;}
  h3.ticket {
    margin: 0 0 5px;
    font-weight: 500;
  }
  
  .hidethis {
    display: none;
  }
  
  .isgiftcard_false {
    display: none !important;
  }
  
  .giftcard_msg {
    width:100%;
    color:#d51034;
    margin-top: 5px;
  }
  
  .terms {
    width: 100%;
    margin-top: 20px;
  }
  
  .terms a { font-weight: 700; }
  
  .terms input {
    display: inline-block;
    height: 16px !important;
    width: 16px !important;
  }
  
  h2.top {
    margin-top: 0;
    font-size: 22px;
    font-weight: 600;
  }
  
  h3.top {
    color:#9FC700;
    font-weight: 500;
    margin-bottom: 0;
  }
  
  #driver_dl {
    width: calc(50% - 10px);
    margin-right: 10px;
  }
  
  #driver_ed {
    width: calc(50% - 10px);
    margin-left: 10px;
  }
  /*
  .PhoneInput {
    width: calc(50% - 10px);
    margin-right: 10px;
    float: left;
  }
  */
  
  #passnum {
    width: calc(50% - 10px);
    margin-left: 10px;
  }
  
  #pax_nationality {
    width: calc(50% - 10px);
    margin-right: 10px;
  }