@media only screen and (max-width: 1340px) { 
    
    div.content, header .wrapper, .wrapper {
        padding: 0px 20px;
    }

    .tripContainer {padding: 0px 20px;}

}

@media only screen and (max-width: 980px) { 

    .valmynd {
        display: none;
    }

    .ferdin.w {
        width: calc(100% - 0px);
    }

    .sidebar, .bokunarvel, .requestbooking {
        width:100%;
        max-width:100%;
        padding: 0;
    }

    .inc-pack {
        width:100%;
        margin-bottom: 20px;
    }

    .projects_items .item, .greenprojects_items .item {
        display: inline-block;
        width: 33.333334%;
        flex: 33.333334%;
        max-width: 33.333334%;
        padding-right: 10px;
        margin: 20px 0;
    }

    .projects_items .item:nth-child(4n+4), .greenprojects_items .item:nth-child(4n+4) {
        padding-right: 10px;
    }

    .projects_items .item:nth-child(3n+3), .greenprojects_items .item:nth-child(3n+3) {
        padding-right: 10px;
    }

}

@media only screen and (max-width: 680px) {

    .projects_items .item, .greenprojects_items .item {
        display: inline-block;
        width: 50%;
        flex: 50%;
        max-width: 50%;
        padding-right: 10px;
        margin: 20px 0;
    }

    .projects_items .item:nth-child(4n+4), .greenprojects_items .item:nth-child(2n+2) {
        padding-right: 10px;
    }

    .projects_items .item:nth-child(3n+3), .greenprojects_items .item:nth-child(3n+3) {
        padding-right: 10px;
    }


    .itemlist {
        display: block;
        gap: 0px;
        margin-bottom: 20px;
    }

    .itemlist .item {
        flex: none;
        padding: 0px;
        width: 100%;
        margin-bottom: 20px;
    }

    .itemlist .item:first-child {
        margin: 0 10px 20px 0;
    }

    .itemlist .item:last-child {
        margin: 0 10px 20px 0;
    }

    footer .storata {
        display: inline-block;
        width: 100%;
        vertical-align: top;
        padding-right: 0px;
    }

    footer .bauga, footer .geira, footer .budda { width:100%; }

    .menu_canvas, .extra_canvas {
        width: 100%;
        display: inline-block;
        vertical-align: top;
    }

    .popupmenu .extra_canvas ul li {
        margin: 5px 0;
        text-align: left;
    }

    .sidebar {
        width: 100%;
        padding: 20px 0;
        font-size: 20px;
    }

    .ferdin.w {
        width: calc(100% - 0px);
        padding-right: 0px;
    }

    .sidebar.is-sticky {
        position: relative !important;
        top: 98px;
    }

    .sidebar, .bokunarvel, .requestbooking {max-width: 100%; }

    .myndasafn {
        height: auto;
    }

    .myndasafn .alpha {
        width: 60%;
        margin-bottom: 20px;
    }

    .myndasafn .beta {
        width: 40%;
        margin-bottom: 20px;
        padding: 0 0 0 5px;
    }

    .myndasafn .gamma {
        width: 40%;
        padding: 0 5px 0 0;
    }

    .myndasafn .delta {
        width: 60%;
    }

    .itineraryitem .gallery {
        width: 100%;
    }

    .itineraryitem .itinerary_info {
        width: 100%;
        padding: 0px;
    }

    .day_activitie .stop {
        width: 100%;
        height: auto;
    }

    .day_activitie .infotext {
        display: inline-block;
        width: calc(100% - 0px);
        min-height: 200px;
        padding: 10px 0;
    }

    .more_info_day .wrapper {padding:0 10px; }

}
@media only screen and (max-width: 480px) {
    .projects_items .item, .greenprojects_items .item {
        display: inline-block;
        width: 100%;
        flex: 100% 1;
        max-width: 100%;
        padding-right: 10px;
        margin: 20px 0;
        padding: 0;
    }

    header ul {
        display: inline-block;
        float: right;
        margin: 15px 10px 0 0 ;
        font-size: 16px;
        padding: 0;
    }

    .submenu {
        margin: 20px auto 0;
    }

    .merki {
        margin-left: 10px;
    }

    footer {
        float: left;
    }

    .tripContainer {
        padding: 0px !important;
        margin: 20px auto 40px !important;
    }

    .info {
        display: inline-block;
        float: left;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    .myndasafn div figure {
        width: 100%;
        height: 200px;
    }

    .myndasafn .delta figure {
        height: 260px;
    }

    .myndasafn .gamma figure {
        height: 125px;
    }

    .myndasafn .delta a {
        display: none;
    }

    h1.tripname {
        font-size: 28px;
    }

    .itineraryitem h3 {
        margin: 10px 0 0;
        line-height: 1.3;
    }

    .itineraryitem .itinerary_info {
        min-height: 291px;
    }

    .close_me {
        left: auto;
        right: 20px;
    }

    footer ul {
        float: none;
        margin: 0 auto;
    }

}